import React from "react";
import OrderDetails from "../components/orders/OrderDetails";
import PrivateRoutesLogin from "../components/PrivateRoutes";

const OrderScreen = () => {
  return (
    <div>
      <OrderDetails />
    </div>
  );
};

export default OrderScreen;
