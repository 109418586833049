import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../constants/url";
import Spinner from "../components/Spinner";
import { Link } from "react-router-dom";

const SearchResults = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchQuery = queryParams.get("query")?.toLowerCase() || "";

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BASE_URL}/products/search?query=${searchQuery}`
        );
        setProducts(response.data);
        setLoading(false);
      } catch (error) {
        setError("Error fetching products");
        setLoading(false);
      }
    };

    fetchProducts();
  }, [searchQuery]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (products.length === 0) {
    return (
      <div className="text-2xl text-center font-bold m-8">
        No products found for "{searchQuery}".
      </div>
    );
  }

  return (
    <div className="container max-w-screen-2xl p-4 mb-20">
      <h1 className="text-2xl font-bold mb-4">Search Results</h1>
      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {products.map((product) => (
          <div key={product._id} className="p-2 sm:p-4">
            <div className="bg-white rounded-lg overflow-hidden relative group">
              <div className="w-full h-[180px] sm:h-[400px] flex items-center justify-center bg-gray-100 relative">
                <Link to={`/product/${product._id}`} className="w-full h-full">
                  <img
                    src={product.image}
                    alt={product.name}
                    className="w-full h-full rounded-lg"
                  />
                </Link>
              </div>
              <div className="p-4 text-left">
                <h3
                  className="text-sm sm:text-lg lg:text-xl font-semibold mb-1"
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "25ch",
                  }}
                >
                  {product.name}
                </h3>
                <div className="flex flex-col sm:flex-row items-start sm:items-center space-x-0 sm:space-x-2 mb-4">
                  <div className="flex items-center space-x-2">
                    <span className="text-lg sm:text-xl lg:text-2xl font-bold text-red-600">
                      ₹{product.discountPrice}
                    </span>
                    <span className="text-sm sm:text-base lg:text-xl line-through text-[#969595]">
                      ₹{product.price}
                    </span>
                  </div>
                  <span className="text-sm sm:text-base lg:text-lg text-[#5C2028] font-medium mt-2 sm:mt-0 ml-2">
                    ({product.discountPercentage}% OFF)
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchResults;
