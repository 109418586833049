import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile, updateUserProfile } from "../store/slices/userSlice";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-modal";
import Spinner from "../components/Spinner";

const ProfileScreen = () => {
  const dispatch = useDispatch();
  const { userProfile, status, error } = useSelector((state) => state.user);
  console.log(userProfile, "11");

  const [profileData, setProfileData] = useState(userProfile);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("userInformation"))._id;
    dispatch(fetchUserProfile(userId));
  }, [dispatch]);

  useEffect(() => {
    setProfileData(userProfile);
  }, [userProfile]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    try {
      const userInformation = JSON.parse(
        localStorage.getItem("userInformation")
      );
      const userId = userInformation._id;

      await dispatch(updateUserProfile({ ...profileData, userId }));
      setIsModalOpen(false);

      toast.success("Profile updated successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Failed to update profile:", error);
      toast.error("Failed to update profile.");
    }
  };

  const userInformation = JSON.parse(localStorage.getItem("userInformation"));
  console.log(userInformation.phoneNumber == "", "49");

  return (
    <div style={styles.container}>
      {status === "loading" && <Spinner />}
      <Toaster />
      <div style={styles.profileSection}>
        <h1 style={styles.welcomeMessage}>Welcome to GustyGlow</h1>
        {userInformation?.googleId ? (
          <div style={styles.userInfo}>
            <img
              src={userInformation.photoUrl}
              alt="Profile"
              style={styles.profileImage}
            />
            <h2 style={styles.userName}>{userInformation.name}</h2>
          </div>
        ) : (
          <h2 style={styles.userName}>{userInformation.name}</h2>
        )}
        <div style={styles.userDetails}>
          <div style={styles.detailItem}>
            <label style={styles.label}>Email:</label>
            <span style={styles.value}>{userInformation.email}</span>
          </div>
          {userInformation.phoneNumber !== "" ? (
            <div style={styles.detailItem}>
              <label style={styles.label}>Phone:</label>
              <span style={styles.value}>{userInformation.phoneNumber}</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <button onClick={() => setIsModalOpen(true)} style={styles.editButton}>
          Edit
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Edit Profile"
        style={modalStyles}
      >
        <div style={styles.modalContent}>
          <button
            onClick={() => setIsModalOpen(false)}
            style={styles.closeButton}
          >
            &times;
          </button>
          <h2 style={styles.modalTitle}>Update Profile</h2>
          <form onSubmit={handleUpdateProfile} style={styles.form}>
            <div style={styles.formGroup}>
              <label htmlFor="name" style={styles.formLabel}>
                Name
              </label>
              <input
                type="text"
                name="name"
                value={profileData?.name || ""}
                onChange={handleInputChange}
                style={styles.formInput}
              />
            </div>
            <div style={styles.formGroup}>
              <label htmlFor="email" style={styles.formLabel}>
                Email
              </label>
              <input
                type="email"
                name="email"
                value={profileData?.email || ""}
                onChange={handleInputChange}
                style={styles.formInput}
              />
            </div>
            {userInformation.phoneNumber !== "" ? (
              <div style={styles.formGroup}>
                <label htmlFor="phone" style={styles.formLabel}>
                  Phone
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={profileData?.phoneNumber || ""}
                  onChange={handleInputChange}
                  style={styles.formInput}
                />
              </div>
            ) : (
              ""
            )}

            <button type="submit" style={styles.updateButton}>
              Save
            </button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    padding: "2rem",
    fontFamily: '"poppins", Helvetica, Arial, sans-serif',
  },
  profileSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "auto",
    maxWidth: "500px",
  },
  welcomeMessage: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: "1rem",
    color: "#eac375",
  },
  userInfo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  profileImage: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    marginBottom: "1rem",
  },
  userName: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  userDetails: {
    width: "100%",
    textAlign: "left",
    marginBottom: "1rem",
    fontSize: "20px",
  },
  detailItem: {
    display: "flex",
    marginBottom: "0.5rem",
  },
  label: {
    fontWeight: "bold",
    marginRight: "1rem",
  },
  value: {
    color: "#555",
  },
  editButton: {
    backgroundColor: "#f4e5c8",
    color: "#000",
    padding: "0.5rem 1rem",
    borderRadius: "5px",
    cursor: "pointer",
    border: "none",
    width: "100px",
  },
  modalContent: {
    position: "relative",
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "500px",
    width: "100%",
    margin: "auto",
  },
  closeButton: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    background: "none",
    border: "none",
    fontSize: "1.5rem",
    cursor: "pointer",
    color: "#000",
  },
  modalTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "1rem",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
  },
  formLabel: {
    marginBottom: "0.5rem",
    fontWeight: "bold",
  },
  formInput: {
    padding: "0.5rem",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  updateButton: {
    backgroundColor: "#f4e5c8",
    color: "#000",
    padding: "0.5rem 1rem",
    borderRadius: "5px",
    cursor: "pointer",
    border: "none",
    alignSelf: "center",
  },
};

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    border: "none",
    borderRadius: "10px",
    width: "90%",
    maxWidth: "500px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

export default ProfileScreen;


