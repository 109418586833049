import React from "react";

const ShippingPolicy = () => {
  return (
    <div className="lg:px-20 lg:py-10 p-8 mt-24">
      <div className="container mx-auto">
        <h1 className="text-5xl font-medium">Shipping Policy</h1>
        <div className="bg-white p-6 rounded-lg my-8  ">
          <h3 className="text-xl font-bold mb-4">Shipping Information</h3>
          <p className="text-gray-700 mb-4">
            We offer free express shipping for all orders. Shipping times may
            vary depending on your location and availability of the items. The
            shipment will happen within 1 to 4 days from the order placed and it
            will be deliver within a 5 to 6 days from shipment.
          </p>

          {/* <h3 className="text-xl font-bold mb-4">International Shipping</h3>
          <p className="text-gray-700 mb-4">
            We ship internationally to over 20 countries. International orders
            may be subject to customs duties and taxes imposed by the
            destination country. The recipient is responsible for paying these
            fees.
          </p> */}

          {/* <h3 className="text-xl font-bold mb-4">Shipping Methods</h3>
          <p className="text-gray-700 mb-4">
            We use reputable carriers such as FedEx, UPS, and DHL for shipping
            our products. You can choose your preferred shipping method during
            checkout.
          </p> */}
          {/* 
          <h3 className="text-xl font-bold mb-4">Shipping Restrictions</h3>
          <p className="text-gray-700 mb-4">
            We do not ship to P.O. boxes. Please provide a physical address for
            delivery.
          </p> */}

          {/* <h3 className="text-xl font-bold mb-4">Lost or Damaged Shipments</h3>
          <p className="text-gray-700 mb-4">
            In the rare event that your shipment is lost or damaged during
            transit, please contact our customer support team immediately. We
            will work with the carrier to resolve the issue and ensure a
            replacement or refund if necessary.
          </p> */}

          {/* <h3 className="text-xl font-bold mb-4">Contact Us</h3>
          <p className="text-gray-700 mb-4">
            If you have any questions about our Shipment Policy, please contact
            us:
          </p> */}
          {/* <ul className="list-disc list-inside mb-4 text-gray-700">
            <li>Email: support@example.com</li>
            <li>Phone: +1 123 456 7890</li>
          </ul>
          <p className="text-gray-700 mb-4">
            This Shipment Policy page was last updated on July 4, 2024.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default ShippingPolicy;
