import React, { useState } from "react";
import { FaStar, FaChevronLeft, FaChevronRight } from "react-icons/fa";

const testimonials = [
  {
    image: "./assets/testimonial/1.jpeg",
    text: "I recently purchased a beautiful heart-shaped silver necklace from this website, and I couldn't be more thrilled with my purchase.",
    name: "Anshika Gupta",
  },
  {
    image: "./assets/testimonial/2.jpeg",
    text: "I bought a set of rose gold earrings from this site, and they are absolutely stunning. The earrings have a beautiful, warm glow that complements my style perfectly",
    name: "Musaddique Shaikh",
  },
  {
    image: "./assets/testimonial/3.jpeg",
    text: "My experience with this jewelry website has been nothing short of amazing. I ordered a trio of bracelets in different colors, and each one is a work of art. The colors are vibrant, and the quality of the materials is evident.",
    name: "Rakshit Mathur",
  },
];

const Testimonial = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  // Get the testimonials for display
  const mainTestimonial = testimonials[currentIndex];
  const leftTestimonial =
    testimonials[
      (currentIndex - 1 + testimonials.length) % testimonials.length
    ];
  const rightTestimonial =
    testimonials[(currentIndex + 1) % testimonials.length];

  return (
    <div className="bg-gray-100 py-8 px-4 lg:py-16 lg:px-8 text-center relative font-poppins">
      <h2 className="font-light mb-4 lg:mb-8 text-2xl lg:text-4xl">
        Testimonials
      </h2>
      <div className="max-2xl mx-auto relative flex items-center justify-center">
        {/* Navigation Arrows */}
        <button
          onClick={prevTestimonial}
          className="absolute left-2 sm:left-4 top-1/2 transform -translate-y-1/2 bg-transparent text-[#5C2028] sm:bg-gray-200 sm:text-gray-600 p-1 sm:p-2 rounded-full shadow-lg hover:bg-gray-400 transition z-10"
        >
          <FaChevronLeft className="text-2xl sm:text-3xl lg:text-4xl" />
        </button>
        <button
          onClick={nextTestimonial}
          className="absolute right-2 sm:right-4 top-1/2 transform -translate-y-1/2 bg-transparent text-[#5C2028] sm:bg-gray-200 sm:text-gray-600 p-1 sm:p-2 rounded-full shadow-lg hover:bg-gray-400 transition z-10"
        >
          <FaChevronRight className="text-2xl sm:text-3xl lg:text-4xl" />
        </button>
        {/* Main Testimonial Container */}
        <div className="flex flex-col items-center text-center p-4 bg-white rounded-lg shadow-lg w-full lg:w-4/5 relative z-0">
          {/* Images Row */}
          <div className="flex justify-center items-center mb-4">
            {/* Left Decorative Image */}
            <img
              src={leftTestimonial.image}
              alt={leftTestimonial.name}
              className="hidden sm:block w-8 h-8 sm:w-12 sm:h-12 lg:w-16 lg:h-16 rounded-full shadow-lg"
            />
            {/* Main Testimonial Image */}
            <img
              src={mainTestimonial.image}
              alt={mainTestimonial.name}
              className="w-16 h-16 sm:w-24 sm:h-24 lg:w-28 lg:h-28 rounded-full shadow-lg mx-2 sm:mx-4"
            />
            {/* Right Decorative Image */}
            <img
              src={rightTestimonial.image}
              alt={rightTestimonial.name}
              className="hidden sm:block w-8 h-8 sm:w-12 sm:h-12 lg:w-16 lg:h-16 rounded-full shadow-lg"
            />
          </div>
          {/* Testimonial Text */}
          <p className="text-sm sm:text-lg text-gray-600 mb-4">
            {mainTestimonial.text}
          </p>
          {/* Star Rating */}
          <div className="flex justify-center mb-4">
            {[...Array(5)].map((_, i) => (
              <FaStar
                key={i}
                className="text-yellow-500"
                style={{ color: "#FFC107" }}
                size={20}
              />
            ))}
          </div>
          {/* Testimonial Name */}
          <p className="text-lg sm:text-xl font-semibold text-gray-800">
            {mainTestimonial.name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
