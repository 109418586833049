import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

import { BASE_URL } from "../../constants/url";

// Async thunk to fetch products
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async () => {
    const { data } = await axios.get(`${BASE_URL}/products/`);
    return data;
  }
);

// Async thunk to fetch products by category
export const fetchProductsByCategory = createAsyncThunk(
  "products/fetchProductsByCategory",
  async (categoryName) => {
    const { data } = await axios.get(
      `${BASE_URL}/products/category-products/${categoryName}`
    );
    return { categoryName, products: data.data.products };
  }
);

const productSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    categoryProducts: {},
    cart: JSON.parse(localStorage.getItem("cart")) || [],
    loading: false,
  },
  reducers: {
    setProducts(state, action) {
      state.products = action.payload;
    },
    setCategoryName(state, action) {
      state.categoryName = action.payload;
    },
    addToCart(state, action) {
      const productId = action.payload;
      const productToAdd =
        state.products.find((p) => p._id === productId) ||
        Object.values(state.categoryProducts)
          .flat()
          .find((p) => p._id === productId);

      if (productToAdd) {
        const existingProduct = state.cart.find(
          (item) => item._id === productId
        );

        if (!existingProduct) {
          const updatedCart = [...state.cart, { ...productToAdd, quantity: 1 }];
          state.cart = updatedCart;
          localStorage.setItem("cart", JSON.stringify(updatedCart));
          toast.success("Product added to cart!");
        } else {
          toast.warning("Product is already in cart!");
        }
      } else {
        toast.error("Product not found!");
      }
    },
    removeFromCart(state, action) {
      const idToRemove = action.payload;
      const updatedCart = state.cart.filter((item) => item._id !== idToRemove);
      state.cart = updatedCart;
      localStorage.setItem("cart", JSON.stringify(updatedCart));
      toast.success("Product removed from the cart");
    },
    incrementQuantity(state, action) {
      const idToIncrement = action.payload;
      const itemToIncrement = state.cart.find(
        (item) => item._id === idToIncrement
      );
      if (itemToIncrement) {
        itemToIncrement.quantity++;
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },
    decrementQuantity(state, action) {
      const idToDecrement = action.payload;
      const itemToDecrement = state.cart.find(
        (item) => item._id === idToDecrement
      );
      if (itemToDecrement && itemToDecrement.quantity > 1) {
        itemToDecrement.quantity--;
        localStorage.setItem("cart", JSON.stringify(state.cart));
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(fetchProductsByCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductsByCategory.fulfilled, (state, action) => {
        state.loading = false;
        const { categoryName, products } = action.payload;
        state.categoryProducts[categoryName] = products;
      })
      .addCase(fetchProductsByCategory.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setProducts,
  setCategoryName,
  addToCart,
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
} = productSlice.actions;

export default productSlice.reducer;
