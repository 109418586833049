import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../constants/url";

export const fetchUserProfile = createAsyncThunk(
  "user/fetchUserProfile",
  async (userId, thunkAPI) => {
    const token = JSON.parse(localStorage.getItem("userInformation")).token;
    try {
      const response = await axios.get(`http://localhost:5000/users/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "user/updateUserProfile",
  async (userProfile, thunkAPI) => {
    const { userId, ...profileData } = userProfile;
    const token = JSON.parse(localStorage.getItem("userInformation")).token;

    try {
      const response = await axios.put(
        BASE_URL + `/users/profile/${userId}`, // Corrected endpoint to include userId in the URL
        profileData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Update localStorage with new user information
      const updatedUserInformation = {
        ...JSON.parse(localStorage.getItem("userInformation")),
        ...profileData,
      };
      localStorage.setItem(
        "userInformation",
        JSON.stringify(updatedUserInformation)
      );

      return response.data; // Return response data to be handled by Redux
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data); // Handle error with rejection value
    }
  }
);

const initialState = {
  user: null,
  userPofile: {
    name: "",
    email: "",
    phoneNumber: "",
  },
  status: "idle",
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.userProfile = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { setUser, clearUser } = userSlice.actions;
export const selectUser = (state) => state.user.user;
export default userSlice.reducer;
