import React from 'react'
import Anklets from '../components/shop/anklets/Anklets'

const AnkletScreen = () => {
  return (
    <div>
      <Anklets />
    </div>
  )
}

export default AnkletScreen
