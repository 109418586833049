import React from 'react'
import Cart from '../components/cart/Cart'

const CartScreen = () => {
  return (
    <div>
     <Cart />
    </div>
  )
}

export default CartScreen
