import React from "react";

const Crumb = () => {
  return (
    <div>
      <div>
        <div className="w-full bg-[#E9C876] py-8"> 
          <div className="container mx-auto text-center">
            <nav aria-label="breadcrumb">
              <ol className="inline-flex items-center space-x-2">
                <li>
                  <a href="/" className="text-gray-700 hover:text-gray-900">
                    Home
                  </a>
                </li>
                <li>
                  <span className="text-gray-700"> &gt; </span>
                </li>
                <li className="text-[#5C2020] font-bold">Bulk Order</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crumb;
