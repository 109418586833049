import React, { useState, useEffect } from "react";
import {
  FaRegHeart,
  FaChevronDown,
  FaChevronUp,
  FaHeart,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner";
import {
  addToCart,
  fetchProductsByCategory,
} from "../../../store/slices/productSlice";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../../store/slices/wishlistSlice";
import axios from "axios"; // Import axios for making HTTP requests
import { BASE_URL } from "../../../constants/url";

const Rings = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [banner, setBanner] = useState(null); // New state for banner
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const { categoryProducts, loading: productLoading } = useSelector(
    (state) => state.products
  );
  const wishlist = useSelector((state) => state.wishlist.wishlist);

  useEffect(() => {
    // Fetch products for the 'Rings' category
    dispatch(fetchProductsByCategory("Rings"));
  }, [dispatch]);

  useEffect(() => {
    if (categoryProducts["Rings"]) {
      setSortedProducts(categoryProducts["Rings"]);
    }
  }, [categoryProducts]);

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/category/rings`);
        const banners = response.data.data.banners; // Adjust as necessary based on the actual API response structure
        setBanner(banners.find((b) => b.category === "Rings"));
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchBanner();
  }, []);

  const handleAddToCart = (id) => {
    dispatch(addToCart(id));
  };

  const handleWishlistToggle = (product) => {
    const isInWishlist = wishlist.some((item) => item._id === product._id);
    if (isInWishlist) {
      dispatch(removeFromWishlist(product._id));
    } else {
      dispatch(addToWishlist(product));
    }
  };

  const handleSort = (order) => {
    const sorted = [...sortedProducts].sort((a, b) => {
      if (order === "lowToHigh") {
        return a.discountPrice - b.discountPrice;
      }
      if (order === "highToLow") {
        return b.discountPrice - a.discountPrice;
      }
      return 0;
    });
    setSortedProducts(sorted);
    setIsDropdownOpen(false);
  };

  if (loading) return <Spinner />;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="font-poppins">
      <div className="relative w-full h-auto overflow-hidden">
        {banner ? (
          <img
            src={"." + banner.imageLink} // Use the banner image URL
            className="w-full h-32 sm:h-64 lg:h-96"
            alt="Category Banner"
          />
        ) : (
          <Spinner /> // Show spinner while loading the banner
        )}
      </div>
      <div>
        <div className="w-full bg-[#E9C876] py-6 sm:py-8">
          <div className="container mx-auto text-center px-4">
            <nav aria-label="breadcrumb">
              <ol className="inline-flex items-center space-x-2">
                <li>
                  <a
                    href="/"
                    className="text-gray-700 hover:text-gray-900 text-sm sm:text-base"
                  >
                    Home
                  </a>
                </li>
                <span className="text-gray-700"> &gt; </span>
                <li>
                  <a
                    href="/"
                    className="text-gray-700 hover:text-gray-900 text-sm sm:text-base"
                  >
                    Shop
                  </a>
                </li>
                <li>
                  <span className="text-gray-700"> &gt; </span>
                </li>
                <li className="text-[#5C2020] font-bold text-sm sm:text-base">
                  Rings
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="container mx-auto p-4 sm:p-6 lg:p-8">
        <div className="flex flex-col sm:flex-row justify-between items-left border-b-2 border-black pb-4 mb-6">
          <div className="relative inline-block text-left mb-4 sm:mb-0">
            <button
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="inline-flex justify-center items-center text-gray-700 text-sm sm:text-base"
            >
              Sort by
              {isDropdownOpen ? (
                <FaChevronUp className="ml-2" />
              ) : (
                <FaChevronDown className="ml-2" />
              )}
            </button>
            {isDropdownOpen && (
              <div className="absolute mt-2 w-44 bg-[white] border border-gray-200 rounded shadow-lg z-10">
                <ul className="py-1 text-gray-700 text-sm">
                  <li>
                    <button
                      onClick={() => handleSort("lowToHigh")}
                      className="block px-4 py-2 text-left w-full"
                    >
                      Low to High
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => handleSort("highToLow")}
                      className="block px-4 py-2 text-left w-full"
                    >
                      High to Low
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {productLoading ? (
            <Spinner />
          ) : (
            sortedProducts.map((product) => (
              <div key={product._id} className="p-2 sm:p-4">
                <div className="bg-white rounded-lg overflow-hidden relative group">
                  <div className="w-full h-[180px] sm:h-[400px] flex items-center justify-center bg-gray-100 relative">
                    <Link
                      to={`/product/${product._id}`}
                      className="w-full h-full"
                    >
                      <img
                        src={product.image}
                        alt={product.name}
                        className="w-full h-full rounded-lg"
                      />
                    </Link>
                    <button
                      onClick={() => handleWishlistToggle(product)}
                      className="absolute top-4 right-4 text-2xl hover:text-red-600 transition-colors"
                    >
                      {wishlist.some((item) => item._id === product._id) ? (
                        <FaHeart color="red" />
                      ) : (
                        <FaRegHeart color="black" />
                      )}
                    </button>
                  </div>
                  <div className="p-4 text-left">
                    <h3
                      className="text-sm sm:text-lg lg:text-xl font-semibold mb-1"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "25ch",
                      }}
                    >
                      {product.name}
                    </h3>
                    <div className="flex flex-col sm:flex-row items-start sm:items-center space-x-0 sm:space-x-2 mb-4">
                      <div className="flex items-center space-x-2">
                        <span className="text-lg sm:text-xl lg:text-2xl font-bold text-red-600">
                          ₹{product.discountPrice}
                        </span>
                        <span className="text-sm sm:text-base lg:text-xl line-through text-[#969595]">
                          ₹{product.price}
                        </span>
                      </div>
                      <span className="text-sm sm:text-base lg:text-lg text-[#5C2028] font-medium mt-2 sm:mt-0 ml-2">
                        ({product.discountPercentage}% OFF)
                      </span>
                    </div>
                    <button
                      className="w-full py-2 bg-[#E9C876] text-white font-bold rounded-lg hover:bg-[#d1a963] transition duration-200 text-[14px] lg:text-[16px]"
                      onClick={() => handleAddToCart(product._id)}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Rings;
