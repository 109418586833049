import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Spinner from "../components/Spinner";
import { BASE_URL } from "../constants/url";

const OrderSuccessScreen = () => {
  const { id } = useParams(); // Get the order ID from the URL
  const navigate = useNavigate();

  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const goToHomepage = () => {
    navigate("/");
  };

  useEffect(() => {
    // Fetch order details from the backend
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(
          BASE_URL + `/orders/order-details/${id}`,
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.getItem("userInformation")).token
              }`,
            },
          }
        );
        if (response.data.status) {
          setOrderDetails(response.data.data); // Access the data field
        } else {
          setError("Failed to fetch order details.");
        }
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-100">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-100">
        <p className="text-[red]">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-4">
      <div className="bg-white p-6 rounded-md shadow-md w-full max-w-md">
        <div className="flex justify-center items-center">
          <FaCheckCircle className="text-green-500 text-5xl" />
        </div>
        <h2 className="text-2xl font-semibold text-center mt-4 text-gray-800">
          Order Successful!
        </h2>
        <p className="text-center text-gray-600 mt-2">
          Thank you for your purchase! Here are your order details:
        </p>
        <div className="mt-4">
          <h3 className="font-semibold text-gray-700">Product Details:</h3>
          {orderDetails &&
            orderDetails.products.map((product, index) => (
              <div key={index} className="border-b py-2 flex items-center">
                <img
                  src={product.productImage}
                  alt={product.productName}
                  className="w-16 h-16 object-cover mr-4 rounded"
                />
                <div>
                  <p className="text-gray-700">
                    <span className="font-semibold">Product:</span>{" "}
                    {product.productName}
                  </p>
                  <p className="text-gray-700">
                    <span className="font-semibold">Quantity:</span>{" "}
                    {product.quantity}
                  </p>
                  <p className="text-gray-700">
                    <span className="font-semibold">Price:</span> ₹
                    {product.price}
                  </p>
                </div>
              </div>
            ))}
          <div className="mt-4">
            <p className="font-semibold text-gray-800">
              Total Amount: ₹{orderDetails.total}
            </p>
          </div>
        </div>
        <button
          onClick={goToHomepage}
          className="w-full bg-[#F5F5F5] text-white py-2 mt-6 rounded-md hover:bg-[#edecec] transition duration-300"
        >
          Go Back to Homepage
        </button>
      </div>
    </div>
  );
};

export default OrderSuccessScreen;
