import React from "react";
import { FaWhatsapp, FaInstagram } from "react-icons/fa";
import FooterBottom from "./FooterBottom"; // Import the FooterBottom component

import FooterMain from "./FooterMain";

const Footer = () => {
  return (
    <>
      <FooterMain />
      <FooterBottom />
    </>
  );
};

export default Footer;
