import React from 'react'
import SingleProduct from '../components/singleproduct/SingleProduct'

const SingleProductScreen = () => {
  return (
    <div>
      <SingleProduct />
    </div>
  )
}

export default SingleProductScreen
