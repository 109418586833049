import React from "react";
import HeaderTop from "./HeaderTop";
import Navbar from "./Navbar";




const Header = () => {
	return (
		<>
			<HeaderTop />
			<Navbar />
   
		</>
	);
};

export default Header;
