import React, { useState, useEffect } from "react";
import axios from "axios";
import TableComponent from "./TableComponent";
import { CSVLink } from "react-csv";
import BulkOrderModalComponent from "./BulkOrderModalComponent";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants/url";
import Spinner from "../Spinner";

const BulkOrder = () => {
  const [products, setProducts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getBulkOrders();
  }, []);

  const getBulkOrders = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/bulkorder`);
      const productsWithName = data.data
        .map((product) => ({
          ...product,
          name: `${product.first_name} ${product.last_name}`,
        }))
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)); // Sort by latest date

      setProducts(productsWithName);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleEdit = (product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const handleDelete = async (id) => {
    const verify = window.confirm("Are you sure you want to delete?");
    if (verify) {
      try {
        await axios.delete(`${BASE_URL}/bulkorder/${id}`);
        setProducts(products.filter((product) => product._id !== id));
        toast.success("Details deleted successfully");
      } catch (error) {
        console.error(error);
        toast.error("Failed to delete order");
      }
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  const handleOrderUpdated = () => {
    getBulkOrders(); // Fetch updated orders from the database
  };

  const columns = [
    { Header: "ID", accessor: "_id" },
    { Header: "Name", accessor: "name" },
    { Header: "Number", accessor: "phone_number" },
    { Header: "Email", accessor: "email" },
    { Header: "Company Name", accessor: "company_name" },
    { Header: "Category", accessor: "category" },
    { Header: "Product Name", accessor: "product_name" },
    { Header: "Quantity", accessor: "quantity" },
    { Header: "Additional Information", accessor: "additional_information" },
    {
      Header: "Date & Time",
      accessor: "createdAt",
      Cell: ({ cell: { value } }) => new Date(value).toLocaleString(), // Format date and time
    }, // New Date column
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-[green] text-[white] px-2 py-1 rounded"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-[red] text-[white] px-2 py-1 rounded"
            onClick={() => handleDelete(row.original._id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const csvHeaders = [
    { label: "Name", key: "name" },
    { label: "Number", key: "phone_number" },
    { label: "Email", key: "email" },
    { label: "Company Name", key: "company_name" },
    { label: "Category", key: "category" },
    { label: "Product Name", key: "product_name" },
    { label: "Quantity", key: "quantity" },
    { label: "Additional Information", key: "additional_information" },
    { label: "Date", key: "date" }, // Include Date in CSV
  ];

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="ml-[280px]">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl">Bulk Orders</h1>
            <CSVLink
              data={products}
              headers={csvHeaders}
              filename="bulk_orders.csv"
              className="bg-[#5f202b] text-[white] px-4 py-2 rounded"
            >
              Download CSV
            </CSVLink>
          </div>
          <TableComponent columns={columns} data={products} />
          {modalOpen && (
            <BulkOrderModalComponent
              isOpen={modalOpen}
              onRequestClose={handleModalClose}
              product={selectedProduct}
              onOrderUpdated={handleOrderUpdated}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default BulkOrder;
