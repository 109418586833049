import React from "react";
import "./index.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import HomeScreen from "./screens/HomeScreen";
import Footer from "./components/Footer/Footer";
import Header from "./components/header/Header";
import BulkorderScreen from "./screens/BulkorderScreen";
import LoginScreen from "./screens/LoginScreen";
import SignupScreen from "./screens/SignupScreen";
import Bracelets from "./components/shop/bracelets/Bracelets";
import WishlistScreen from "./screens/WishlistScreen";
import SingleProductScreen from "./screens/SingleProductScreen";
import OrderScreen from "./screens/OrderScreen";
import CartScreen from "./screens/CartScreen";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import RefundPolicy from "./screens/RefundPolicy";
import TermsAndConditions from "./screens/TermsAndConditions";
import ContactScreen from "./screens/ContactScreen";
import ShippingPolicy from "./screens/ShippingPolicy";
import SearchResults from "./screens/SearchResults";
import Blogs from "./screens/Blogs";
import DashboardScreen from "./screens/DashboardScreen";
import EarringsScreen from "./screens/EarringsScreen";
import AnkletScreen from "./screens/AnkletScreen";
import RingScreen from "./screens/RingScreen";
import ChainsScreen from "./screens/ChainsScreen";
import AdminLayout from "./components/AdminLayout";
import ProfileScreen from "./screens/ProfileScreen";
import MyOrders from "./screens/MyOrders";
import BlogDetail from "./screens/BlogDatils";
import AdminLoginScreen from "./screens/AdminLoginScreen";
import PrivateRoute from "./PrivateRoute";
import CreateNewPassword from "./screens/CreateNewPassword";
import OrderSuccessScreen from "./screens/OrderSuccessScreen";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AdminLayout />}>
          <Route path="/admin/dashboard" element={<DashboardScreen />} />
        </Route>
        <Route
          path="*"
          element={
            <>
              <Header />
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/bulkorder" element={<BulkorderScreen />} />
                <Route path="/login" element={<LoginScreen />} />
                <Route path="/signup" element={<SignupScreen />} />
                <Route path="/bracelets" element={<Bracelets />} />
                <Route path="/wishlist" element={<WishlistScreen />} />
                <Route path="/product/:id" element={<SingleProductScreen />} />
                <Route path="/checkout" element={<PrivateRoute />}>
                  <Route path="/checkout" element={<OrderScreen />} />
                </Route>
                <Route path="/cart" element={<CartScreen />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/refund-policy" element={<RefundPolicy />} />
                <Route
                  path="/terms-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="/contact" element={<ContactScreen />} />
                <Route path="/shipping-policy" element={<ShippingPolicy />} />
                <Route path="/search" element={<SearchResults />} />
                <Route path="/blog" element={<Blogs />} />
                <Route path="/blog/:id" element={<BlogDetail />} />
                <Route path="/earrings" element={<EarringsScreen />} />
                <Route path="/anklets" element={<AnkletScreen />} />
                <Route path="/rings" element={<RingScreen />} />
                <Route path="/chains" element={<ChainsScreen />} />
                <Route path="/profile" element={<ProfileScreen />} />
                <Route path="/myorders" element={<MyOrders />} />
                <Route path="/admin" element={<AdminLoginScreen />} />
                <Route path="/order-success/:id" element={<OrderSuccessScreen />} />
                <Route
                  path="/create-new-password"
                  element={<CreateNewPassword />}
                />
              </Routes>
              <Footer />
            </>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
