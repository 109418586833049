import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  wishlist: JSON.parse(localStorage.getItem("wishlist")) || [],
};

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  reducers: {
    addToWishlist(state, action) {
      const productToAdd = action.payload;
      // Check if the product is already in the wishlist based on _id
      if (!state.wishlist.some((item) => item._id === productToAdd._id)) {
        // If not in wishlist, add it
        const updatedWishlist = [...state.wishlist, productToAdd];
        state.wishlist = updatedWishlist;
        localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
        toast.success("Product added to wishlist!");
      } else {
        // If already in wishlist, show a warning
        toast.warning("Product is already in wishlist!");
      }
    },
    removeFromWishlist(state, action) {
      const idToRemove = action.payload;
      // Filter out the item to remove from the wishlist
      const updatedWishlist = state.wishlist.filter(
        (item) => item._id !== idToRemove
      );
      state.wishlist = updatedWishlist;
      localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      toast.success("Product removed from wishlist");
    },
  },
});

export const { addToWishlist, removeFromWishlist } = wishlistSlice.actions;
export default wishlistSlice.reducer;
