import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants/url";

export const BannerModal = ({
  isOpen,
  onRequestClose,
  banner,
  onBannerUpdated,
}) => {
  const [formData, setFormData] = useState({
    order: "",
    link: "",
    imageLink: "",
    category: "",
    description: "",
  });

  useEffect(() => {
    if (banner) {
      setFormData({
        order: banner.order || "",
        link: banner.link || "",
        imageLink: banner.imageLink || "",
        category: banner.category || "",
        description: banner.description || "",
      });
    }
  }, [banner]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (banner) {
        // Update banner
        await axios.put(
          `${BASE_URL}/home/update-banner/${banner._id}`,
          formData
        );
        toast.success("Banner updated successfully");
      } else {
        // Add new banner
        await axios.post(`${BASE_URL}/home/add-banners`, formData);
        toast.success("Banner added successfully");
      }
      onRequestClose();
      onBannerUpdated(); // Refetch banners
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="relative max-w-lg mx-auto my-10 p-6 bg-[white] border border-gray-300 rounded shadow-lg"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-50"
    >
      <button
        onClick={onRequestClose}
        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="max-h-[70vh] overflow-y-auto p-4">
        <h2 className="text-xl mb-4">
          {banner ? "Edit Banner" : "Add Banner"}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label className="block mb-2">Order</label>
            <input
              type="number"
              name="order"
              value={formData.order}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Link</label>
            <input
              type="text"
              name="link"
              value={formData.link}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Image URL</label>
            <input
              type="text"
              name="imageLink"
              value={formData.imageLink}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Category</label>
            <input
              type="text"
              name="category"
              value={formData.category}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Description</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
        </form>
      </div>
      <div className="flex justify-end mt-4">
        <button
          type="submit"
          className="bg-[green] text-[white] px-4 py-2 rounded"
          onClick={handleSubmit}
        >
          {banner ? "Update" : "Add"}
        </button>
      </div>
    </Modal>
  );
};
