// src/store/slices/orderSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orderDetails: [],
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addOrderDetail(state, action) {
      state.orderDetails.push(action.payload);
    },
    setOrderDetails(state, action) {
      console.log(action.payload, "16")
      state.orderDetails = action.payload;
    },
  },
});

export const { addOrderDetail, setOrderDetails } = orderSlice.actions;
export default orderSlice.reducer;
