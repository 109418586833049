import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { BASE_URL } from "../../constants/url";

const Contact = () => {
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    companyName: "",
    category: "",
    productName: "",
    quantity: "",
    additionalInformation: "",
    email: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });
  };

  const validateForm = () => {
    const errors = {};

    // Loop through form values and check for required fields
    Object.keys(formValues).forEach((key) => {
      if (key !== "additionalInformation" && !formValues[key]) {
        errors[key] = "This field is required";
      }
    });

    // Email validation
    if (formValues.email && !/\S+@\S+\.\S+/.test(formValues.email)) {
      errors.email = "Email address is invalid";
    }

    // Phone number validation
    if (formValues.phoneNumber && formValues.phoneNumber.length !== 10) {
      errors.phoneNumber = "Phone number must be 10 digits";
    }

    // Quantity validation
    if (formValues.quantity && formValues.quantity <= 0) {
      errors.quantity = "Quantity must be greater than 0";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await axios.post(BASE_URL + "/bulkorder", {
          first_name: formValues.firstName,
          last_name: formValues.lastName,
          phone_number: formValues.phoneNumber,
          company_name: formValues.companyName,
          category: formValues.category,
          product_name: formValues.productName,
          quantity: formValues.quantity,
          additional_information: formValues.additionalInformation,
          email: formValues.email,
        });
        if (response.status === 201) {
          toast.success("Query saved! We will contact you soon.");
          setFormValues({
            firstName: "",
            lastName: "",
            phoneNumber: "",
            companyName: "",
            category: "",
            productName: "",
            quantity: "",
            additionalInformation: "",
            email: "",
          });
          setFormErrors({});
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="w-full bg-gray-100 py-8 font-poppins">
      <div className="container max-w-screen-xl mx-auto px-4 py-8">
        {/* Grid Container */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Column 1: Content Section */}
          <div className="flex flex-col space-y-4">
            <h2 className="text-2xl lg:text-3xl font-bold">
              Place Bulk Order With Us
            </h2>
            <p className="text-gray-700 w-full">
              We understand the importance of finding the perfect pieces for
              your special occasions, corporate gifts, or retail needs. That's
              why we offer exclusive bulk order options tailored to meet your
              unique requirements. Enjoy competitive pricing, customized
              designs, and exceptional quality when you choose to order in bulk
              with us.
            </p>
            <img
              src="./assets/testimonial/Bulk order .png"
              alt="Placeholder"
              className="rounded-xl w-full h-auto md:w-[538px] md:h-[400px] sm:h-[319px]"
            />
          </div>

          {/* Column 2: Form Section */}
          <form className="space-y-4" onSubmit={handleSubmit}>
            {/* First Name and Last Name */}
            <div className="flex flex-col lg:flex-row lg:space-x-4">
              <div className="flex-1">
                <label
                  htmlFor="firstName"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  First Name
                  <span className="text-[red]" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  value={formValues.firstName}
                  onChange={handleInputChange}
                  placeholder="First Name"
                  className="w-full p-2 border border-gray-300 rounded-xl bg-[#F5F5F5] placeholder-black"
                />
                {formErrors.firstName && (
                  <p className="text-[red] text-sm">{formErrors.firstName}</p>
                )}
              </div>
              <div className="flex-1 mt-4 md:mt-0">
                <label
                  htmlFor="lastName"
                  className="block text-gray-700 font-semibold mb-2 "
                >
                  Last Name
                  <span className="text-[red]" style={{ color: "red" }}>
                    *
                  </span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  placeholder="Last Name"
                  className="w-full p-2 border border-gray-300 rounded-xl bg-[#F5F5F5] placeholder-black"
                />
                {formErrors.lastName && (
                  <p className="text-[red] text-sm">{formErrors.lastName}</p>
                )}
              </div>
            </div>

            {/* Email */}
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 font-semibold mb-2"
              >
                Email
                <span className="text-[red]" style={{ color: "red" }}>
                  *
                </span>
              </label>
              <input
                type="email"
                id="email"
                value={formValues.email}
                onChange={handleInputChange}
                placeholder="Email"
                className="w-full p-2 border border-gray-300 rounded-xl bg-[#F5F5F5] placeholder-black"
              />
              {formErrors.email && (
                <p className="text-[red] text-sm">{formErrors.email}</p>
              )}
            </div>

            {/* Phone Number */}
            <div>
              <label
                htmlFor="phoneNumber"
                className="block text-gray-700 font-semibold mb-2"
              >
                Phone Number
                <span className="text-[red]" style={{ color: "red" }}>
                  *
                </span>
              </label>
              <input
                type="text"
                id="phoneNumber"
                value={formValues.phoneNumber}
                onChange={handleInputChange}
                placeholder="Phone Number"
                className="w-full p-2 border border-gray-300 rounded-xl bg-[#F5F5F5] placeholder-black"
              />
              {formErrors.phoneNumber && (
                <p className="text-[red] text-sm">{formErrors.phoneNumber}</p>
              )}
            </div>

            {/* Company Name */}
            <div>
              <label
                htmlFor="companyName"
                className="block text-gray-700 font-semibold mb-2"
              >
                Company Name
                <span className="text-[red]" style={{ color: "red" }}>
                  *
                </span>
              </label>
              <input
                type="text"
                id="companyName"
                value={formValues.companyName}
                onChange={handleInputChange}
                placeholder="Company Name"
                className="w-full p-2 border border-gray-300 rounded-xl bg-[#F5F5F5] placeholder-black"
              />
              {formErrors.companyName && (
                <p className="text-[red] text-sm">{formErrors.companyName}</p>
              )}
            </div>

            {/* Category */}
            <div>
              <label
                htmlFor="category"
                className="block text-gray-700 font-semibold mb-2"
              >
                Category
                <span className="text-[red]" style={{ color: "red" }}>
                  *
                </span>
              </label>
              <input
                type="text"
                id="category"
                value={formValues.category}
                onChange={handleInputChange}
                placeholder="Category"
                className="w-full p-2 border border-gray-300 rounded-xl bg-[#F5F5F5] placeholder-black"
              />
              {formErrors.category && (
                <p className="text-[red] text-sm">{formErrors.category}</p>
              )}
            </div>

            {/* Product Name */}
            <div>
              <label
                htmlFor="productName"
                className="block text-gray-700 font-semibold mb-2"
              >
                Product Name
                <span className="text-[red]" style={{ color: "red" }}>
                  *
                </span>
              </label>
              <input
                type="text"
                id="productName"
                value={formValues.productName}
                onChange={handleInputChange}
                placeholder="Product Name"
                className="w-full p-2 border border-gray-300 rounded-xl bg-[#F5F5F5] placeholder-black"
              />
              {formErrors.productName && (
                <p className="text-[red] text-sm">{formErrors.productName}</p>
              )}
            </div>

            {/* Quantity */}
            <div>
              <label
                htmlFor="quantity"
                className="block text-gray-700 font-semibold mb-2"
              >
                Quantity
                <span className="text-[red]" style={{ color: "red" }}>
                  *
                </span>
              </label>
              <input
                type="number"
                id="quantity"
                value={formValues.quantity}
                onChange={handleInputChange}
                placeholder="Quantity"
                className="w-full p-2 border border-gray-300 rounded-xl bg-[#F5F5F5] placeholder-black"
              />
              {formErrors.quantity && (
                <p className="text-[red] text-sm">{formErrors.quantity}</p>
              )}
            </div>

            {/* Additional Information */}
            <div>
              <label
                htmlFor="additionalInformation"
                className="block text-gray-700 font-semibold mb-2"
              >
                Additional Information
              </label>
              <textarea
                id="additionalInformation"
                value={formValues.additionalInformation}
                onChange={handleInputChange}
                placeholder="Additional Information"
                rows="4"
                className="w-full p-2 border border-gray-300 rounded-xl bg-[#F5F5F5] placeholder-black"
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full lg:w-1/2 bg-[#e9c876] text-white p-2 rounded-xl"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
