import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeFromWishlist } from "../store/slices/wishlistSlice";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { RxCross2 } from "react-icons/rx";
import { addToCart } from "../store/slices/productSlice";
import { fetchProducts } from "../store/slices/productSlice";
import { FaShoppingCart } from "react-icons/fa"; // Example cart icon, replace with your actual implementation
import { Link } from "react-router-dom";

const WishlistScreen = () => {
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.wishlist.wishlist);

  const handleRemoveFromWishlist = (id) => {
    dispatch(removeFromWishlist(id));
  };

  useEffect(() => {
    // Assuming you have a fetchProducts thunk to get products
    dispatch(fetchProducts());
  }, [dispatch]);

  const handleAddToCart = (id) => {
    console.log(id, "19");
    // Placeholder function to simulate adding to cart
    console.log("Adding to cart:", id);
    // Implement actual addToCart logic using Redux action dispatch
    dispatch(addToCart(id));
  };

  return (
    <div className="font-poppins">
      {/* Breadcrumb */}
      <div className="w-full bg-[#E9C876] py-8">
        <div className="container mx-auto text-center">
          <nav aria-label="breadcrumb">
            <ol className="inline-flex items-center space-x-2">
              <li>
                <a href="/" className="text-gray-700 hover:text-gray-900">
                  Home
                </a>
              </li>
              <li>
                <span className="text-gray-700"> &gt; </span>
              </li>
              <li className="text-[#5C2020] font-bold">Wishlist</li>
            </ol>
          </nav>
        </div>
      </div>

      {/* Wishlist Products */}
      <div className="container mx-auto py-8 font-poppins">
        <h2 className="text-center text-3xl mt-8 mb-8">My Wishlist</h2>
        {wishlist.length === 0 ? (
          <div className="flex flex-col items-center mt-8">
            <img src="./assets/category/images.png" alt="Empty Wishlist" />
            <p className="text-center text-2xl my-4">Your wishlist is empty.</p>
            <p className="text-center text-2xl mb-4 text-[#e7c873]">
              Let's fill it up with some amazing jewellery!
            </p>
            <a href="/" className="text-center">
              <button
                className="px-6 py-3 text-white font-bold rounded-lg hover:bg-blue-600 transition duration-200"
                style={{ backgroundColor: "#f4e5c8", marginBottom: "40px" }}
              >
                Explore Now
              </button>
            </a>
          </div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-2 sm:gap-4 lg:gap-6 px-2 sm:px-4 lg:px-8">
            {wishlist.map((product) => (
              <div key={product._id} className="p-2 sm:p-3">
                <div className="bg-white rounded-lg overflow-hidden relative group w-full">
                  <Link to={`/product/${product._id}`}>
                    <img
                      src={product.image}
                      alt={product.name}
                      className="w-full h-full rounded-2xl"
                    />
                  </Link>

                  <button
                    onClick={(event) => {
                      event.stopPropagation(); // Prevent the click event from bubbling up to the Link
                      handleRemoveFromWishlist(product._id);
                    }}
                    className="absolute top-2 right-2 text-2xl text-gray-300 hover:text-red-600 transition-colors"
                  >
                    <RxCross2 />
                  </button>

                  <div className="p-4 text-left">
                    <h3
                      className="text-lg font-semibold mb-1"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "25ch",
                      }}
                    >
                      {product.name}
                    </h3>
                    <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 mb-4">
                      <div className="flex gap-2 sm:inline">
                        <span className="text-2xl font-bold text-red-600">
                          ₹{product.discountPrice}
                        </span>
                        <span className="text-gray-500 line-through text-xl sm:ml-2">
                          ₹{product.price}
                        </span>
                      </div>
                      <span className="text-[#5C2028] mt-2 sm:mt-0 sm:ml-2">
                        ({product.discountPercentage}% OFF)
                      </span>
                    </div>

                    <button
                      className="w-full py-2 bg-[#E9C876] text-white font-bold rounded-lg hover:bg-[#d1a963] transition duration-200 text-[14px] lg:text-[16px]"
                      onClick={() => handleAddToCart(product._id)}
                    >
                      Add to Cart
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default WishlistScreen;
