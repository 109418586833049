import React from "react";

const Categories = () => {
  return (
    <div className="font-poppins">
      <div className="max-w-screen-xl mx-auto px-4 pt-8  ">
        {/* Category Section */}
        <div className="mb-8">
          {/* Category Grid */}
          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 mb-8">
            {/* Item 1 */}
            <div className="flex flex-col items-center">
              <div className="relative flex items-center justify-start w-full">
                <div className="absolute left-0 h-full flex flex-col justify-center items-start p-4">
                  <div className="bg-gray-800 bg-opacity-75 py-2 rounded-lg text-left">
                    <h3 className="text-[10px] lg:text-2xl font-semibold mb-2 text-white">
                      Heart of Silver <br /> Chain
                    </h3>
                    <a href="/chains">
                      <button className="bg-[#E9C876] text-black px-2 lg:px-4 py-1 lg:py-2 text-[10px] lg:text-base rounded-full hover:bg-opacity-90">
                        Shop Now
                      </button>
                    </a>
                  </div>
                </div>
                <img
                  src="./assets/category/download 7.png"
                  alt="Product 1"
                  className="mb-4 rounded-lg w-full"
                />
              </div>
            </div>

            {/* Item 2 */}
            <div className="flex flex-col items-center">
              <div className="relative flex items-center justify-start w-full">
                <div className="absolute left-0 h-full flex flex-col justify-center items-start p-4">
                  <div className="bg-gray-800 bg-opacity-75 py-2 rounded-lg text-left">
                    <h3 className="text-[10px] lg:text-2xl font-semibold mb-2 text-white">
                      Rosy Elegance <br /> Earrings
                    </h3>
                    <a href="/earrings">
                      <button className="bg-[#E9C876] text-black px-2 lg:px-4 py-1 lg:py-2 text-[10px] lg:text-base rounded-full hover:bg-opacity-90">
                        Shop Now
                      </button>
                    </a>
                  </div>
                </div>
                <img
                  src="./assets/category/download 10.png"
                  alt="Product 2"
                  className="mb-4 rounded-lg w-full"
                />
              </div>
            </div>

            {/* Item 3 */}
            <div className="flex flex-col items-center col-span-2 sm:col-span-2 lg:col-span-1">
              <div className="relative flex items-center justify-start w-full">
                <div className="absolute left-0 h-full flex flex-col justify-center items-start p-4">
                  <div className="bg-gray-800 bg-opacity-75 py-2 rounded-full text-left">
                    <h3 className="text-[10px] lg:text-2xl font-semibold mb-2 text-white">
                      Radiant Trio <br />
                      Rings
                    </h3>
                    <a href="/anklets">
                      <button className="bg-[#E9C876] text-black px-2 lg:px-4 py-1 lg:py-2 text-[10px] lg:text-base rounded-full hover:bg-opacity-90">
                        Shop Now
                      </button>
                    </a>
                  </div>
                </div>
                <img
                  src="./assets/category/download.jpg"
                  alt="Product 3"
                  className=" w-full rounded-[15px] lg:rounded-[30px] lg:h-[272px] h-[130px] border border-customGray sm:border-[1px] md:border-[2px]"
                />
              </div>
            </div>
          </div>

          {/* Item 4 and 5 */}
          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-4 lg:gap-8 mb-8">
            {/* Item 4 */}
            <div className="flex flex-col items-center">
              <div className="relative flex items-center justify-start w-full">
                <div className="absolute left-0 h-full flex flex-col justify-center items-start p-4">
                  <div className="bg-gray-800 bg-opacity-75 py-2 rounded-full text-left">
                    <h3 className="text-[10px] lg:text-2xl font-semibold mb-2 text-white">
                      Classic <br /> Anklets
                    </h3>
                    <a href="/rings">
                      <button className="bg-[#E9C876] text-black px-2 lg:px-4 py-1 lg:py-2 text-[10px] lg:text-base rounded-full hover:bg-opacity-90">
                        Shop Now
                      </button>
                    </a>
                  </div>
                </div>
                <img
                  src="./assets/category/Anklets-removebg-preview.png"
                  alt="Product 4"
                  className="mb-4 w-full h-[113px] lg:h-[272px] rounded-[15px] lg:rounded-[30px] border border-customGray sm:border-[1px] md:border-[2px]"
                />
              </div>
            </div>

            {/* Item 5 */}
            <div className="flex flex-col items-center">
              <div className="relative flex items-center justify-start w-full">
                <div className="absolute left-0 h-full flex flex-col justify-center items-start p-4">
                  <div className="bg-gray-800 bg-opacity-75 py-2 rounded-full text-left">
                    <h3 className="text-[10px] lg:text-2xl font-semibold mb-2 text-white">
                      Silver Serenity <br /> Bracelets
                    </h3>
                    <a href="/bracelets">
                      <button className="bg-[#E9C876] text-black px-2 lg:px-4 py-1 lg:py-2 text-[10px] lg:text-base rounded-full hover:bg-opacity-90">
                        Shop Now
                      </button>
                    </a>
                  </div>
                </div>
                <img
                  src="./assets/category/Untitled design (4).png"
                  alt="Product 5"
                  className="mb-4 w-full h-[113px] lg:h-[272px] rounded-[15px] lg:rounded-[30px] border border-customGray sm:border-[1px] md:border-[2px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
