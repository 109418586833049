import React, { useState, useEffect } from "react";
import axios from "axios";
import TableComponent from "./TableComponent";
import ContactModal from "./ContactModal";
import { BASE_URL } from "../../constants/url";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";
import Spinner from "../Spinner";

const ContactUs = () => {
  const [contacts, setContacts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getContactUs();
  }, []);

  const getContactUs = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/contact`);

      // Sort the contacts by the latest date
      const sortedContacts = data.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setContacts(sortedContacts);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEdit = (contact) => {
    setSelectedContact(contact);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    const verify = window.confirm("Are you sure you want to delete?");
    if (verify) {
      axios
        .delete(`${BASE_URL}/contact/${id}`)
        .then(() => {
          setContacts(contacts.filter((contact) => contact._id !== id));
          toast.success("Contact deleted successfully");
        })
        .catch((error) => {
          console.error(error);
          toast.error("Failed to delete contact");
        });
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedContact(null);
  };

  const handleContactUpdated = () => {
    getContactUs(); // Fetch updated contacts from the database
  };

  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Number", accessor: "number" },
    { Header: "Email", accessor: "email" },
    { Header: "Message", accessor: "message" },
    {
      Header: "Date",
      accessor: "createdAt",
      Cell: ({ value }) => new Date(value).toLocaleString(), // Format date and time
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            className="bg-[green] text-[white] px-2 py-1 rounded"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-[red] text-[white] px-2 py-1 rounded"
            onClick={() => handleDelete(row.original._id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const csvHeaders = [
    { label: "Name", key: "name" },
    { label: "Number", key: "number" },
    { label: "Email", key: "email" },
    { label: "Message", key: "message" },
    { label: "Date", key: "createdAt" }, // Include date in CSV
  ];

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="ml-[280px]">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl">Contact Us</h1>
            <CSVLink
              data={contacts}
              headers={csvHeaders}
              filename="contacts.csv"
              className="bg-[#5f202b] text-[white] px-4 py-2 rounded"
            >
              Download CSV
            </CSVLink>
          </div>
          <TableComponent columns={columns} data={contacts} />
          {modalOpen && (
            <ContactModal
              isOpen={modalOpen}
              onRequestClose={handleModalClose}
              contact={selectedContact}
              onContactUpdated={handleContactUpdated}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ContactUs;
