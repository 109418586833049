import React from 'react'
import Chains from '../components/shop/chains/Chains'

const ChainsScreen = () => {
  return (
    <div>
      <Chains />
    </div>
  )
}

export default ChainsScreen
