import React from "react";
import ChartComponent from "./ChartComponent";

const Dashboard = () => {
  return (
    <div className="grid grid-cols-2 gap-4">
      <ChartComponent title="Products" />
      <ChartComponent title="Orders" />
      <ChartComponent title="Users" />
      <ChartComponent title="Sales" />
    </div>
  );
};

export default Dashboard;
