import React from 'react'

const RefundPolicy = () => {
  return (
    <section className=" lg:px-20 lg:py-10 p-8 mt-24">
      <h1 className="text-5xl font-medium">Return & Refund Policy</h1>
      <div className="line w-full border-b border-gray-300 my-3 mb-10"></div>

      <div className="text-area font-normal text-lg lg:w-[100%] w-full">
        <h2 className=" text-3xl font-semibold mb-2">Returns</h2>
        <p className="mb-2">
          Customers have a period of 7 days from the date of receiving the
          product to initiate a return and they are responsible for bearing the
          charges associated with returning the product. Upon successful return,
          the refund process will be initiated and customers can expect the
          refund to be processed within 7 business days. 
        </p>

        <p className="mb-4">
          To initiate a return, customers must contact our customer support team
          within the stipulated 7-day period. Provide relevant details,
          including the order number, reason for return, and any supporting
          documentation required.
        </p>

        <h2 className=" text-2xl font-semibold mb-2">Important Notes</h2>
        <ul className=" list-disc ml-8 mb-5">
          <li>
            Returned items must be in their original condition, with all tags
            and packaging intact.
          </li>
          <li>
            Customers must obtain a return authorization from our customer
            support team before sending back the product.
          </li>
          <li>Refunds will be issued through the original payment method.</li>
        </ul>

        <h2 className=" text-2xl font-semibold mb-2">
          Non-Applicable Return and Refund Cases
        </h2>
        <ul className=" list-disc ml-8 mb-5">
          <li>
            Product Damage: Returns and refunds are not applicable if the
            product has been damaged post-purchase.
          </li>
          <li>
            Video Proof: Return and refund requests based solely on video proof
            without supporting evidence will not be accepted.
          </li>
        </ul>

        <h2 className=" text-2xl font-semibold mb-2"> Exchange Policy:</h2>
        <p className=" mb-8">
          We regret to inform you that we do not currently offer an exchange
          policy. Customers are encouraged to initiate a return for the product
          they wish to exchange and place a new order for the desired item.
        </p>

        <h2 className=" text-3xl font-semibold mb-2">Returns</h2>
        <p>
          We will notify you once we’ve received and inspected your return, and
          let you know if the refund was approved or not. If approved, you’ll be
          automatically refunded on your original payment method within 7
          business days. Please remember it can take some time for your bank or
          credit card company to process and post the refund too. If more than
          15 business days have passed since we’ve approved your return, please
          contact us at gustyglowindia@gmail.com
        </p>
      </div>
    </section>
  );
}

export default RefundPolicy