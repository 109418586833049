import React, { useEffect } from "react";
import Categories from "../components/categories/Categories";
import TrendingProducts from "../components/trending/TrendingProducts";
import Testimonial from "../components/testimonials/Testimonial";
import Parallax from "../components/hero/Parallax";
import HomeBannerNew from "../components/banner/HomeBannerNew";

const HomeScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HomeBannerNew />
      <Categories />
      <TrendingProducts />
      {/* <Hero /> */}
      <Parallax />
      <Testimonial />
    </div>
  );
};

export default HomeScreen;
