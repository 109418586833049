import React from 'react'
import Rings from '../components/shop/rings/Rings'

const RingScreen = () => {
  return (
    <div>
      <Rings/>
    </div>
  )
}

export default RingScreen
