import React, { useState } from "react";
import { FaWhatsapp, FaChevronDown, FaPlus } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import FooterBottom from "./FooterBottom";

const FooterMain = () => {
  const [isQuickLinksOpen, setIsQuickLinksOpen] = useState(false);
  const [isCompanyOpen, setIsCompanyOpen] = useState(false);

  const toggleQuickLinks = () => setIsQuickLinksOpen(!isQuickLinksOpen);
  const toggleCompany = () => setIsCompanyOpen(!isCompanyOpen);

  return (
    <div>
      <footer className="bg-[#FFF7E4] text-white pb-8 font-poppins">
        <div className="container mx-auto px-12">
          {/* First Row */}
          <div className="flex flex-wrap justify-between md:justify-between items-center mb-4">
            {/* Column 1: Logo, Address, Contact */}
            <div className="w-full md:w-1/4 mb-6 md:mb-0 flex flex-col items-center md:items-start text-center md:text-left">
            <img
                src="./assets/logo/logo4-edited.png"
                alt="Logo"
                className="lg:h-36 h-24 py-2"
              />
              <div className="flex justify-center space-x-4 mt-4">
                <a
                  href="https://www.instagram.com/gusty.glow"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="./assets/icons/15707749 3.png"
                    alt="Instagram"
                    className="w-12 h-12"
                  />
                </a>
                <a
                  href="https://www.facebook.com/GustyGlow"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="./assets/icons/facebook.png"
                    alt="Facebook"
                    className="w-12 h-12"
                  />
                </a>
              </div>
              <address className="not-italic mb-2 mt-4">
                134-135, Second Floor, Sundar Apartment,
                <br />
                Parshvanath Colony, Nirman Nagar, Jaipur,
                <br />
                Rajasthan, Pincode - 302019
              </address>
              <div className="flex justify-center md:justify-start mb-2">
                <IoCall className="mr-2" size={20} />
                <span>9119311079 - 8178896415</span>
              </div>
              <div className="flex justify-center md:justify-start mb-2">
                <MdEmail className="mr-2" size={20} />
                <a href="mailto:support@gustyglow.com">
                  gustyglowindia@gmail.com
                </a>
              </div>
            </div>

            {/* Column 2: Quick Links */}
            <div
              className="w-full md:w-1/4 mb-6 md:mb-0"
              style={{ marginBottom: "35px" }}
            >
              <h4
                className="text-xl font-semibold mb-4 text-center md:text-left flex items-center justify-center md:justify-start"
                onClick={toggleQuickLinks}
              >
                Quick Links
                <span className="ml-2 md:hidden">
                  {isQuickLinksOpen ? <FaChevronDown /> : <FaPlus />}
                </span>
              </h4>
              <ul
                className={`text-center md:text-left ${
                  isQuickLinksOpen ? "block" : "hidden"
                } md:block`}
              >
                <li className="mb-2">
                  <a href="/" className="hover:underline">
                    Home
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/contact" className="hover:underline">
                    Contact Us
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/blog" className="hover:underline">
                    Blog
                  </a>
                </li>
                <li>
                  <a href="/bulkorder" className="hover:underline">
                    Bulk Orders
                  </a>
                </li>
              </ul>
            </div>

            {/* Column 3: Company */}
            <div
              className="w-full md:w-1/4 mb-6 md:mb-0"
              style={{ marginBottom: "35px" }}
            >
              <h4
                className="text-xl font-semibold mb-4 text-center md:text-left flex items-center justify-center md:justify-start"
                onClick={toggleCompany}
              >
                Company
                <span className="ml-2 md:hidden">
                  {isCompanyOpen ? <FaChevronDown /> : <FaPlus />}
                </span>
              </h4>
              <ul
                className={`text-center md:text-left ${
                  isCompanyOpen ? "block" : "hidden"
                } md:block`}
              >
                <li className="mb-2">
                  <a href="/privacy-policy" className="hover:underline">
                    Privacy Policy
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/refund-policy" className="hover:underline">
                    Refund Policy
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/terms-conditions" className="hover:underline">
                    Terms & Conditions
                  </a>
                </li>
                <li>
                  <a href="/shipping-policy" className="hover:underline">
                    Shipping Policy
                  </a>
                </li>
              </ul>
            </div>

            {/* Column 4: Categories */}
            <div className="w-full md:w-1/4 mb-6 md:mb-0 hidden md:block">
              <h4 className="text-xl font-semibold mb-4 text-center md:text-left">
                Categories
              </h4>
              <ul className="text-center md:text-left">
                <li className="mb-2">
                  <a href="/earrings" className="hover:underline">
                    Earrings
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/bracelets" className="hover:underline">
                    Bracelets
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/rings" className="hover:underline">
                    Rings
                  </a>
                </li>
                <li className="mb-2">
                  <a href="/chains" className="hover:underline">
                    Chains
                  </a>
                </li>
                <li>
                  <a href="/anklets" className="hover:underline">
                    Anklets
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default FooterMain;
