import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Dashboard from "../components/dashboard/Dasboard";
import Sidebar from "../components/dashboard/Sidebar";
import Products from "../components/dashboard/Products";
import Orders from "../components/dashboard/Orders";
import Banners from "../components/dashboard/Banners";
import BulkOrder from "../components/dashboard/Bulkorder";
import ContactUs from "../components/dashboard/ContactUs";
import Users from "../components/dashboard/Users";
import OrderCancelRequests from "../components/dashboard/OrderCancelRequests";
import { BASE_URL } from "../constants/url";

const DashboardScreen = () => {
  const [activeSection, setActiveSection] = useState("dashboard");
  const [cancelRequestCount, setCancelRequestCount] = useState(0);

  useEffect(() => {
    fetchCancelRequestCount();
  }, []);

  const fetchCancelRequestCount = async () => {
    try {
      const response = await axios.get(BASE_URL + "/orders/get-cancel-request");

      let requests = [];
      if (Array.isArray(response.data)) {
        requests = response.data;
      } else if (response.data.data && Array.isArray(response.data.data)) {
        requests = response.data.data;
      } else {
        toast.error("Unexpected response format");
      }

      // Calculate the count of pending requests
      const pendingCount = requests.filter(
        (request) => request.status === "pending"
      ).length;
      setCancelRequestCount(pendingCount);
    } catch (err) {
      console.error(err);
      toast.error("Failed to fetch order cancel requests count");
    }
  };

  return (
    <div className="flex">
      <Sidebar
        setActiveSection={setActiveSection}
        activeSection={activeSection}
        cancelRequestCount={cancelRequestCount}
      />
      <div className="p-4 flex-1">
        {activeSection === "dashboard" && <Dashboard />}
        {activeSection === "products" && <Products />}
        {activeSection === "orders" && <Orders />}
        {activeSection === "banners" && <Banners />}
        {activeSection === "bulkorder" && <BulkOrder />}
        {activeSection === "contact" && <ContactUs />}
        {activeSection === "users" && <Users />}
        {activeSection === "orderCancelRequests" && (
          <OrderCancelRequests setCancelRequestCount={setCancelRequestCount} />
        )}
      </div>
    </div>
  );
};

export default DashboardScreen;
