import React from "react";
import { useParams } from "react-router-dom";

export const blogs = [
  {
    id: 1,
    title: "The Timeless Elegance of Diamond Jewelry",
    date: "July 17, 2024",
    image: "/assets/products/chains/1.png",
    content:
      "Diamonds are a girl's best friend, and for a good reason. They are timeless, elegant, and versatile, making them perfect for any occasion. In this blog, we explore the allure of diamond jewelry and why it remains a top choice for jewelry lovers worldwide. Diamonds are a girl's best friend, and for a good reason. They are timeless, elegant, and versatile, making them perfect for any occasion. In this blog, we explore the allure of diamond jewelry and why it remains a top choice for jewelry lovers worldwide.",
  },
  {
    id: 2,
    title: "How to Choose the Perfect Engagement Ring",
    date: "July 16, 2024",
    image: "/assets/products/rings/3.png",
    content:
      "Choosing the perfect engagement ring can be overwhelming with so many options available. From selecting the right diamond to picking the perfect band, this guide will help you navigate the process and find the ring that best symbolizes your love and commitment.       Choosing the perfect engagement ring can be overwhelming with so many options available. From selecting the right diamond to picking the perfect band, this guide will help you navigate the process and find the ring that best symbolizes your love and commitment.",
  },
  {
    id: 3,
    title: "The History of Gold in Jewelry Making",
    date: "July 15, 2024",
    image: "/assets/products/earrings/4.png",
    content:
      "Gold has been used in jewelry making for thousands of years. Its luster, rarity, and workability make it a preferred material for crafting beautiful pieces. In this blog, we delve into the rich history of gold in jewelry making and its enduring popularity.  Gold has been used in jewelry making for thousands of years. Its luster, rarity, and workability make it a preferred material for crafting beautiful pieces. In this blog we delve into the rich history of gold in jewelry making and its enduring popularity.",
  },
];

const BlogDetail = () => {
  const { id } = useParams();
  const blog = blogs.find((blog) => blog.id === parseInt(id));

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <div className="container mx-auto py-8">
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          <img
            src={blog.image}
            alt={blog.title}
            className="w-full h-auto object-cover"
            style={{ maxWidth: "600px", margin: "0 auto" }}
          />
          <div className="p-4">
            <h1 className="text-4xl font-bold mb-4">{blog.title}</h1>
            <p className="text-gray-600 mb-4">{blog.date}</p>
            <p className="text-gray-700">{blog.content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
