import React, { useState, useEffect } from "react";
import axios from "axios";
import UserModal from "./UserModal";
import { BASE_URL } from "../../constants/url";
import Spinner from "../Spinner";
import { toast } from "react-toastify";
import TableComponent from "./TableComponent";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    setLoading(true);
    axios
      .get(`${BASE_URL}/users/admin/profile`)
      .then((response) => {
        console.log(response.data, "Fetched users"); // Debug log
        if (Array.isArray(response.data.data)) {
          const sortedUsers = response.data.data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUsers(sortedUsers); // Set the sorted users array
        } else {
          console.error("Expected an array but got:", response.data.data);
          setUsers([]); // Fallback to an empty array
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setLoading(false);
      });
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const handleDelete = (id) => {
    const verify = window.confirm("Are you sure?");
    if (verify) {
      axios
        .delete(`${BASE_URL}/users/${id}`)
        .then(() => {
          setUsers(users.filter((user) => user._id !== id));
          toast.success("User deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting user:", error);
        });
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedUser(null);
  };

  const handleUserUpdated = () => {
    fetchUsers();
  };

  const columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Phone", accessor: "phoneNumber" },
    {
      Header: "Admin",
      accessor: "isAdmin",
      Cell: ({ cell: { value } }) => (value ? "Yes" : "No"),
    },
    {
      Header: "Date & Time",
      accessor: "createdAt",
      Cell: ({ cell: { value } }) => new Date(value).toLocaleString(), // Format date and time
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div>
          <button
            className="bg-[green] text-[white] px-2 py-1 mr-2 rounded"
            onClick={() => handleEdit(row.original)}
          >
            Edit
          </button>
          <button
            className="bg-[red] text-[white] px-2 py-1 rounded"
            onClick={() => handleDelete(row.original._id)}
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  return (
    <div className="ml-[280px]">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl">Users</h1>
        {/* <button
          className="bg-[#e7e7e7] text-white px-4 py-2 rounded"
          onClick={() => setModalOpen(true)}
        >
          Add User
        </button> */}
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <TableComponent columns={columns} data={users || []} /> // Ensuring users is always an array
      )}
      {modalOpen && (
        <UserModal
          isOpen={modalOpen}
          onRequestClose={handleModalClose}
          user={selectedUser}
          onUserUpdated={handleUserUpdated}
        />
      )}
    </div>
  );
};

export default Users;
