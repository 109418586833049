import React from "react";
import ProductDetails from "./ProductDeatils";
import Table from "./Table";

const SingleProduct = () => {
  return (
    <div>
      <ProductDetails />
      {/* <Table /> */}
    </div>
  );
};

export default SingleProduct;
