import React from 'react'
import Earrings from '../components/shop/earrings/Earrings'

const EarringsScreen = () => {
  return (
    <div>
      <Earrings />
    </div>
  )
}

export default EarringsScreen
