import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProducts,
  fetchProductsByCategory,
} from "../../store/slices/productSlice";
import Spinner from "../Spinner";
import ProductCard from "../productcard/PorductCard"; // Ensure correct import path

const TrendingProducts = () => {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.products.products); // Accessing all products

  const loading = useSelector((state) => state.products.loading);

  useEffect(() => {
    dispatch(fetchProducts()); // Fetch all products on component mount
  }, []);

  // Example function to fetch category products
  // const fetchCategoryProducts = (categoryName) => {
  //   dispatch(fetchProductsByCategory(categoryName));
  // };

  return (
    <div className="container max-w-screen-xl mx-auto py-8 px-4">
      <h2
        className="font-light text-center mb-8 font-poppins"
        style={{ fontSize: "40px" }}
      >
        Trending Products
      </h2>
      {loading ? (
        <Spinner />
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {products.map((product) => (
            <ProductCard
              key={product._id}
              image={product.image}
              name={product.name}
              price={product.price}
              discountPrice={product.discountPrice}
              discountPercentage={product.discountPercentage}
              category={product.category}
              id={product._id}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default TrendingProducts;
