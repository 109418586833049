import React from 'react'
import Bulkorder from '../components/bulkorder/Bulkorder'


const BulkorderScreen = () => {
  return (
    <div>
      <Bulkorder />
    </div>
  )
}

export default BulkorderScreen
