import React from "react";
import "./ParallaxSection.css";

const Parallax = () => {
  return (
    <div className="text-poppins">
      <div className="parallax-container">
        <div className="parallax-content">
          <div className="text-overlay">
            <h3 className="subheading">Best Deal of the Week</h3>
            <h1 className="heading">
            Elegant Gold Chandeliers Earrings
            </h1>
            {/* <p className="price text-bold">₹600</p> */}
            <a href="/earrings">
            <button className="shop-button">Shop Now</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Parallax;
