import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import productSlice from "./slices/productSlice";
import singleProductSlice from "./slices/singleProductSlice";
import orderSlice from "./slices/orderSlice";
import wishlistSlice from "./slices/wishlistSlice";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { combineReducers } from "redux";

// Configuration for redux-persist
const persistConfig = {
  key: "root",
  storage,
  // Optionally add blacklist or whitelist to include/exclude specific slices
  // blacklist: ['someSlice'], // if you want to exclude certain slices
  // whitelist: ['user', 'products'], // if you only want to persist specific slices
};

// Combine reducers and wrap with persistReducer
const rootReducer = combineReducers({
  user: userSlice,
  products: productSlice,
  singleProduct: singleProductSlice,
  order: orderSlice,
  wishlist: wishlistSlice,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer, // Use the persistedReducer here
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check for redux-persist
    }),
});

const persistor = persistStore(store);

export { store, persistor };
