import React from "react";
// import Breadcrumb from './Breadcrumb'
import Contact from "./Contact";
import Crumb from "./Crumb";

const Bulkorder = () => {
  return (
    <div>
      <Crumb />
      <Contact />
    </div>
  );
};

export default Bulkorder;
