import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const PrivateRoute = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [redirectPath, setRedirectPath] = useState("/");
  const user = JSON.parse(localStorage.getItem("userInformation"));
  const orderDetails = useSelector((state) => state.order.orderDetails);
  console.log(orderDetails, "11")
  const location = useLocation();

//   useEffect(() => {
//     if (!user) {
//       toast.error("You need to log in to access the checkout page.");
//       setRedirectPath("/login");
//       setShouldRedirect(true);
//     } else if (orderDetails.length === 0) {
//       toast.error(
//         "Your cart is empty. Add some products before proceeding to checkout."
//       );

//       setRedirectPath("/cart");
//       setShouldRedirect(true);
//     } else if (orderDetails.length !== 0) {
//       toast.error("Click checkout to order the product");
//       setRedirectPath("/cart");
//       setShouldRedirect(true);
//     }
//   }, [user, orderDetails.length]);

//   if (shouldRedirect) {
//     return <Navigate to={redirectPath} state={{ from: location }} replace />;
//   }

  return <Outlet />;
};

export default PrivateRoute;
