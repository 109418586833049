import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../constants/url";

const OrderModalComponent = ({
  isOpen,
  onRequestClose,
  order,
  onOrderUpdated,
}) => {
  const [formData, setFormData] = useState({
    id: "",
    userId: "",
    productName: "",
    qty: "",
    price: "",
  });

  useEffect(() => {
    if (order) {
      setFormData({
        id: order._id || "",
        userId: order.userId || "",
        productName: order.productName || "",
        qty: order.qty || "",
        price: order.price || "",
      });
    }
  }, [order]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (formData.id) {
        // Update order
        await axios.put(`${BASE_URL}/orders/${formData.id}`, formData, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userInformation")).token
            }`,
          },
        });
        toast.success("Order updated successfully");
      } else {
        // Add new order
        await axios.post(`${BASE_URL}/orders`, formData, {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem("userInformation")).token
            }`,
          },
        });
        toast.success("Order added successfully");
      }
      onRequestClose();
      onOrderUpdated(); // Refetch orders
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="relative max-w-lg mx-auto my-10 p-6 bg-white border border-gray-300 rounded shadow-lg"
      overlayClassName="fixed inset-0 bg-gray-500 bg-opacity-50"
    >
      <button
        onClick={onRequestClose}
        className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div className="max-h-[70vh] overflow-y-auto p-4">
        <h2 className="text-xl mb-4">
          {formData.id ? "Edit Order" : "Add Order"}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="mb-4">
            <label className="block mb-2">User ID</label>
            <input
              type="text"
              name="userId"
              value={formData.userId}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Product Name</label>
            <input
              type="text"
              name="productName"
              value={formData.productName}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Quantity</label>
            <input
              type="number"
              name="qty"
              value={formData.qty}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Price</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
        </form>
      </div>
      <div className="flex justify-end mt-4">
        <button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded"
          onClick={handleSubmit}
        >
          {formData.id ? "Update" : "Add"}
        </button>
      </div>
    </Modal>
  );
};

export default OrderModalComponent;
