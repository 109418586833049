import React from "react";
import { FaTrash, FaPlus, FaMinus, FaShoppingCart } from "react-icons/fa"; // Import icons from FontAwesome
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../Spinner";
import {
  removeFromCart,
  incrementQuantity,
  decrementQuantity,
} from "../../store/slices/productSlice";
import { setOrderDetails } from "../../store/slices/orderSlice";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart, loading } = useSelector((state) => state.products);
  console.log(cart, "15");

  const handleIncrement = (id) => {
    dispatch(incrementQuantity(id));
  };

  const handleDecrement = (id) => {
    dispatch(decrementQuantity(id));
  };

  const handleRemove = (id) => {
    dispatch(removeFromCart(id));
  };

  const calculateSubtotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const calculateDiscount = () => {
    // Example: 10% discount on subtotal
    return calculateSubtotal() * 0.2;
  };

  const calculateShippingCost = () => {
    // Free shipping if discount is more than a certain amount
    const discountThreshold = 100;
    return calculateDiscount() > discountThreshold ? 0 : 50;
  };

  // const calculateTax = () => {
  //   // Example: 5% tax on subtotal
  //   return calculateSubtotal() * 0.05;
  // };

  const calculateTotalCost = () => {
    return calculateSubtotal() - calculateDiscount() + calculateShippingCost();
  };

  const handleCheckout = () => {
    dispatch(setOrderDetails([...cart]));
    navigate("/checkout");
  };

  return (
    <>
      <h2 className="text-center text-3xl font-bold mt-8">My Cart</h2>
      {cart.length === 0 ? (
        <div className="flex flex-col items-center mt-8">
          <img src="./assets/category/download.webp" />
          <p className="text-center text-2xl mb-4">Your cart is empty.</p>
          <p className="text-center text-2xl mb-4 text-[#e7c873]">
            Let's fill it up with some amazing jewellery!
          </p>
          <a href="/" className="text-center">
            <button
              className="px-6 py-3   text-white font-bold rounded-lg hover:bg-blue-600 transition duration-200"
              style={{ backgroundColor: "#f4e5c8", marginBottom: "40px" }}
            >
              Explore Now
            </button>
          </a>
        </div>
      ) : loading ? (
        <Spinner />
      ) : (
        <div className="font-poppins min-h-screen p-8 bg-gray-100">
          <div className="container max-w-screen-xl mx-auto">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-3 md:gap-8">
              {/* Cart Items Section */}
              <div className="md:col-span-2 bg-white rounded-lg shadow-md">
                {cart.map((item) => (
                  <div
                    key={item._id}
                    className="flex border-b border-gray-200 last:border-b-0"
                  >
                    <div className="w-1/3 p-4">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="lg:w-40 lg:h-40 sm:w-20 sm:h-20 object-cover rounded-lg"
                      />
                    </div>
                    <div className="w-2/3 p-4 flex flex-col justify-center">
                      <p
                        className="text-lg font-semibold"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "25ch",
                        }}
                      >
                        {item.name}
                      </p>
                      {/* Container for prices and discount percentage */}
                      <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2 mb-2">
                        {/* Prices */}
                        <div className="flex gap-2 sm:flex-row sm:items-center sm:space-x-2">
                          <span className="text-2xl font-bold text-red-600">
                            ₹{item.discountPrice}
                          </span>
                          <span className="text-gray-500 line-through text-xl sm:ml-2">
                            ₹{item.price}
                          </span>
                        </div>
                        {/* Discount Percentage */}
                        <span className="text-[#5C2028] mt-2 sm:mt-0 sm:ml-2">
                          ({item.discountPercentage}% OFF)
                        </span>
                      </div>
                      <div className="flex items-center mt-2">
                        <button
                          onClick={() => handleDecrement(item._id)}
                          className="px-3 py-1 rounded-full bg-gray-300 text-gray-700"
                        >
                          <FaMinus />
                        </button>
                        <span className="mx-3 font-bold text-lg">
                          {item.quantity}
                        </span>
                        <button
                          onClick={() => handleIncrement(item._id)}
                          className="px-3 py-1 rounded-full bg-gray-300 text-gray-700"
                        >
                          <FaPlus />
                        </button>
                        <button
                          onClick={() => handleRemove(item._id)}
                          className="ml-auto text-red-600"
                        >
                          <FaTrash />
                        </button>
                      </div>
                      <p className="mt-2 text-lg font-bold">
                        Total: ₹{item.discountPrice * item.quantity}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Summary Section */}
              <div className="md:col-span-1 bg-gray-100 rounded-lg shadow-md p-4">
                <h3 className="text-xl font-bold mb-4">Order Summary</h3>
                <div className="flex justify-between items-center mb-4">
                  <span className="text-lg font-bold">Subtotal</span>
                  <span className="text-lg font-semibold">
                    ₹{calculateSubtotal().toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <span className="text-lg font-bold">Discount</span>
                  <span className="text-lg font-semibold">
                    ₹{calculateDiscount().toFixed(2)}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-4">
                  <span className="text-lg font-bold">Shipping Cost</span>
                  <span className="text-lg font-semibold">
                    {calculateShippingCost() === 0
                      ? "Free"
                      : `₹${calculateShippingCost().toFixed(2)}`}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-4 border-b-2">
                  <span className="text-lg font-bold">Tax</span>
                  <span className="text-md font-semibold">
                    Calculate at checkout
                  </span>
                </div>
                <div className="flex justify-between items-center mb-4 ">
                  <span className="text-xl font-bold">Total Cost</span>
                  <span className="text-xl font-semibold">
                    ₹{calculateTotalCost().toFixed(2)}
                  </span>
                </div>

                <button
                  className="w-full py-2 bg-[#E9C876] text-white font-bold rounded-lg hover:bg-[#d1a963] transition duration-200"
                  onClick={handleCheckout}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
