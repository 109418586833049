import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../constants/url";

// Initialize Toastify

const ContactScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [number, setNumber] = useState("");

  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};

    if (name && !/^[a-zA-Z\s-']+$/.test(name)) {
      newErrors.name =
        "Name can contain alphabets, spaces, hyphens, and apostrophes.";
    }

    if (!/^[0-9]{10}$/.test(number)) {
      newErrors.number = "Phone number must be exactly 10 digits.";
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Invalid email format.";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return;
    }

    try {
      await axios.post(BASE_URL + "/contact", {
        name,
        email,
        message,
        number,
      });
      toast.success("Your query has been saved. We will contact you soon.");
      // Optionally clear the form fields
      setName("");
      setEmail("");
      setMessage("");
      setNumber("");
    } catch (error) {
      toast.error("There was an error submitting your form. Please try again.");
    }
  };

  return (
    <div className="h-[100%] light-bg">
      <div className="bg-[#E9C876] py-8">
        <nav className="text-gray-600 text-center font-semibold py-4 px-16">
          <ol className="list-none p-0 inline-flex">
            <li className="flex items-center">
              <Link to="/" className="text-primaryColor hover:underline">
                Home
              </Link>
              <span className="mx-2">/</span>
            </li>
            <li>Contact Us</li>
          </ol>
        </nav>
      </div>
      <div className="cont-form mt-4 container-main">
        <h1 className="text-3xl text-left ml-12 lg:ml-16 xl:text-5xl font-bold text-gray-800">
          Get In touch
        </h1>
        <p className="max-w-lg lg:ml-16 ml-12 mt-4">
          We'd love to hear from you about our entire service. Your comments and
          suggestions will be highly appreciated. Please complete the form
          below.
        </p>
        <form
          onSubmit={handleSubmit}
          className="bg-white md:p-12 p-6 border-cont-form flex justify-between lg:flex-row flex-col lg:gap-4 gap-12"
        >
          <div className="lg:w-1/2 flex flex-col gap-1">
            <div className="w-full max-w-lg px-5 bg-white rounded-lg">
              <label
                className="block text-gray-700 text-xl font-bold mb-2"
                htmlFor="name"
              >
                Name
              </label>
              <input
                className={`text-sm custom-input w-full px-4 py-4 border border-gray-300 rounded-lg shadow-sm transition duration-300 ease-in-out transform focus:-translate-y-1 focus:outline-blue-300 hover:shadow-lg hover:border-blue-300 bg-gray-100 ${
                  errors.name ? "border-red-500" : ""
                }`}
                placeholder="Enter your Name here"
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && (
                <span className="text-red-500 text-sm">{errors.name}</span>
              )}
            </div>

            <div className="w-full max-w-lg px-5 bg-white rounded-lg">
              <label
                className="block text-gray-700 text-xl font-bold mb-2"
                htmlFor="phone"
              >
                Phone No.
              </label>
              <input
                className={`text-sm custom-input w-full px-4 py-4 border border-gray-300 rounded-lg shadow-sm transition duration-300 ease-in-out transform focus:-translate-y-1 focus:outline-blue-300 hover:shadow-lg hover:border-blue-300 bg-gray-100 ${
                  errors.number ? "border-red-500" : ""
                }`}
                placeholder="Enter phone no. here"
                type="text"
                id="phone"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />
              {errors.number && (
                <span className="text-red-500 text-sm">{errors.number}</span>
              )}
            </div>

            <div className="w-full max-w-lg px-5 bg-white rounded-lg">
              <label
                className="block text-gray-700 text-xl font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className={`text-sm custom-input w-full px-4 py-4 border border-gray-300 rounded-lg shadow-sm transition duration-300 ease-in-out transform focus:-translate-y-1 focus:outline-blue-300 hover:shadow-lg hover:border-blue-300 bg-gray-100 ${
                  errors.email ? "border-red-500" : ""
                }`}
                placeholder="Enter your Email"
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && (
                <span className="text-red-500 text-sm">{errors.email}</span>
              )}
            </div>

            <div className="w-full max-w-lg px-5 bg-white rounded-lg">
              <label
                className="block text-gray-700 text-xl font-bold mb-2"
                htmlFor="message"
              >
                Message (Optional)
              </label>
              <textarea
                className={`text-sm custom-input w-full px-4 py-14 border border-gray-300 rounded-lg shadow-sm transition duration-300 ease-in-out transform focus:-translate-y-1 focus:outline-blue-300 hover:shadow-lg hover:border-blue-300 bg-gray-100`}
                placeholder="Enter text here"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <div className="flex">
              <button
                className="text-white bg-[#E9C876] max-w-xl lg:px-52 px-32 py-3 mt-4 ml-4 text-md rounded-xl border-2 border-primaryColor hover:bg-primaryColor hover:text-black"
                type="submit"
              >
                Submit{" "}
                <i className="fa fa-paper-plane pl-2" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div className="lg:w-1/2">
            <div className="form-img bg-white lg:h-[450px] mt-8 h-auto order-last flex justify-center align-middle items-center">
              <img
                src="./assets/contact/7b2aab91-8385-4dc8-aba3-cd6c4b7c902a 1.png"
                alt="form_img"
                className="bg-white flex justify-center align-middle"
              />
            </div>
          </div>
        </form>
      </div>

      <hr className="w-full bg-gray-800 mt-20" />
      <div className="py-16 px-4">
        <div className="container-main flex lg:flex-row flex-col gap-24 xl:gap-32 overflow-hidden">
          <div className="right-cont lg:ml-14 xl:w-2/3 lg:w-1/2 h-96 lg:h-[390px] w-full">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14234.157552668656!2d75.7378139014094!3d26.886371676602405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396db52ce226e659%3A0x1c8f91a964d90b66!2sSundar%20apartment!5e0!3m2!1sen!2sin!4v1721575064327!5m2!1sen!2sin"
              width="100%"
              height="100%"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              style={{ border: "2px solid black" }}
              title="Map"
            ></iframe>
          </div>
          <div className="flex flex-col">
            <div className="address px-5">
              <h3 className="text-2xl font-bold text-gray-700 mb-2">Address</h3>
              <p className="text-gray-600 text-lg mb-1">
                134-135, Second Floor, Sundar Apartment, Parshvanath Colony,
                Nirman Nagar, Jaipur, Rajasthan, Pincode - 302019
              </p>
              <p className="text-gray-600 text-lg mb-4">ZIP Code: 302019</p>
              <h3 className="text-2xl font-bold text-gray-700 mb-2">Phone</h3>
              <p className="text-gray-600 text-lg mb-1">
                9119311079 - 8178896415
              </p>
              <h3 className="text-2xl font-bold text-gray-700 mb-2">Email</h3>
              <p className="text-gray-600 text-lg mb-4">
                gustyglowindia@gmail.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactScreen;
