import React, { useState, useEffect } from "react";
import axios from "axios";
import TableComponent from "./TableComponent";
import { BASE_URL } from "../../constants/url";
import { toast } from "react-toastify";
import Spinner from "../Spinner";

const OrderCancelRequests = ({ setCancelRequestCount }) => {
  const [cancelRequests, setCancelRequests] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCancelRequests();
  }, []);

  const fetchCancelRequests = async () => {
    setLoading(true);
    try {
      const response = await axios.get(BASE_URL + "/orders/get-cancel-request");
      console.log("Response data:", response.data);

      let requests = [];
      if (Array.isArray(response.data)) {
        requests = response.data;
      } else if (response.data.data && Array.isArray(response.data.data)) {
        requests = response.data.data;
      } else {
        toast.error("Unexpected response format");
      }

      // Sort requests by latest date
      requests.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setCancelRequests(requests);

      // Calculate the count of pending requests
      const pendingCount = requests.filter(
        (request) => request.status === "pending"
      ).length;
      setCancelRequestCount(pendingCount);
    } catch (err) {
      console.error(err);
      toast.error("Failed to fetch order cancel requests");
    } finally {
      setLoading(false);
    }
  };

  const approveRequest = async (id) => {
    try {
      const response = await axios.put(
        `${BASE_URL}/orders/approve-cancel/${id}`
      );
      toast.success(response.data.message);
      fetchCancelRequests(); // Refresh the list and count after approval
    } catch (error) {
      console.error(error);
      toast.error("Failed to approve cancel request");
    }
  };

  const columns = [
    { Header: "Request ID", accessor: "_id" },
    { Header: "User ID", accessor: "userId" },
    { Header: "Name", accessor: "name" },
    { Header: "Email", accessor: "email" },
    { Header: "Phone", accessor: "phone" },
    {
      Header: "Product Image",
      accessor: "products[0].productImage",
      Cell: ({ value }) => (
        <img src={value} alt="Product" width={50} height={50} />
      ),
    },
    { Header: "Product Name", accessor: "products[0].productName" },
    { Header: "Quantity", accessor: "products[0].quantity" },
    { Header: "Cancel Reason", accessor: "cancelReason" },
    { Header: "Message", accessor: "otherCancelReason" },
    {
      Header: "Cancel Image",
      accessor: "cancelImage",
      Cell: ({ value }) => {
        if (value) {
          // Cloudinary URLs can be used directly
          return (
            <img
              src={value} // Use the Cloudinary URL directly
              alt="Cancel Request"
              width={50}
              height={50}
            />
          );
        }
        return <span>No Image</span>; // Display a placeholder if no image is available
      },
    },

    { Header: "Status", accessor: "status" },
    {
      Header: "Date & Time",
      accessor: "createdAt",
      Cell: ({ value }) => new Date(value).toLocaleString(), // Format the date
    },
    {
      Header: "Actions",
      id: "actions",
      Cell: ({ row }) =>
        row.original.status === "pending" ? (
          <button
            onClick={() => approveRequest(row.original._id)}
            className="text-blue-500 hover:underline bg-[green] py-2 px-4 rounded-lg text-[white]"
          >
            Approve
          </button>
        ) : (
          <span className="bg-[red] py-2 px-4 rounded-lg text-[white] inline-block">
            Approved
          </span>
        ),
    },
  ];

  return (
    <div className="ml-[280px]">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl">Order Cancel Requests</h1>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <TableComponent columns={columns} data={cancelRequests} />
      )}
    </div>
  );
};

export default OrderCancelRequests;
