import React, { useEffect, useState } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchSingleProduct } from "../../store/slices/singleProductSlice";
import Spinner from "../Spinner";
import { addToCart } from "../../store/slices/productSlice";
import { setOrderDetails } from "../../store/slices/orderSlice";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../store/slices/wishlistSlice";
import { fetchProducts } from "../../store/slices/productSlice";
import Table from "./Table";

const ProductDetails = () => {
  const products = useSelector((state) => state.products.products);
  const wishlist = useSelector((state) => state.wishlist.wishlist);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { product, status, error } = useSelector(
    (state) => state.singleProduct
  );
  const [quantity, setQuantity] = useState(1);
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [mainImage, setMainImage] = useState("");
  const [productState, setProduct] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      dispatch(fetchSingleProduct(id));
    }
    dispatch(fetchProducts());
  }, [id, dispatch]);

  useEffect(() => {
    const inWishlist = wishlist.some((item) => item._id === id);
    setIsInWishlist(inWishlist);
  }, [wishlist, id]);

  useEffect(() => {
    if (product) {
      setMainImage(product.image);
      setProduct(product);
    }
  }, [product]);

  const handleQuantityChange = (amount) => {
    if (quantity + amount > 0) {
      setQuantity(quantity + amount);
    }
  };

  const handleAddToCart = (id) => {
    dispatch(addToCart(id));
  };

  const handleBuyItNow = () => {
    const newOrderDetail = {
      _id: product._id,
      name: product.name,
      image: product.image,
      discountPrice: product.discountPrice,
      price: product.price,
      quantity: quantity,
    };
    dispatch(setOrderDetails([newOrderDetail]));
    navigate("/checkout");
  };

  const handleWishlistToggle = (id) => {
    if (isInWishlist) {
      dispatch(removeFromWishlist(id));
      setIsInWishlist(false);
    } else {
      const productToAdd = {
        _id: product._id,
        name: product.name,
        image: product.image,
        discountPrice: product.discountPrice,
        price: product.price,
        discountPercentage: product.discountPercentage,
      };
      dispatch(addToWishlist(productToAdd));
      setIsInWishlist(true);
    }
  };

  const handleVariantClick = (variant) => {
    console.log(variant, "92");
    if (!productState) return;

    // Swap the main image with the clicked variant image
    const newVariants = productState.variants.map((v) =>
      v === variant ? mainImage : v
    );

    // Set the main image to the clicked variant
    setMainImage(variant);

    // Create a new product object with the updated variants
    const updatedProduct = {
      ...productState,
      variants: newVariants,
    };

    // Update the product state with the new product object
    setProduct(updatedProduct);
  };

  if (status === "loading") {
    return <Spinner />;
  }

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  if (!product) {
    return null;
  }

  return (
    <>
      <div>
        <div>
          <div className="w-full bg-[#E9C876] py-8">
            <div className="container mx-auto text-center">
              <nav aria-label="breadcrumb">
                <ol className="inline-flex items-center space-x-2">
                  <li>
                    <a href="/" className="text-gray-700 hover:text-gray-900">
                      Home
                    </a>
                  </li>
                  <li>
                    <span className="text-gray-700"> &gt; </span>
                  </li>
                  <li>
                    <a href="/" className="text-gray-700 hover:text-gray-900">
                      Products
                    </a>
                  </li>
                  <li>
                    <span className="text-gray-700"> &gt; </span>
                  </li>
                  <li
                    className="text-[#5C2020] font-bold"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "15ch",
                    }}
                  >
                    {product.name}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container max-w-screen-xl mx-auto py-8 px-4 font-poppins">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="flex flex-col items-center lg:items-start h-full">
            <img
              src={mainImage}
              alt={product.name}
              className="w-full rounded-lg mb-4 lg:h-[500px] h-[350px]"
            />
            <div className="grid grid-cols-4 gap-2 mx-auto">
              {productState?.variants?.map((variant, index) => (
                <img
                  key={index}
                  src={variant}
                  alt={`Variant ${index + 1}`}
                  className="w-full h-24 md:h-32 object-cover rounded-lg cursor-pointer"
                  onClick={() => handleVariantClick(variant)}
                />
              ))}
            </div>
          </div>

          <div className="flex flex-col space-y-4">
            <h2 className="text-3xl font-bold">{product.name}</h2>
            <p className="text-gray-700">{product.description}</p>
            <div className="flex items-center space-x-2">
              <span className="text-3xl font-bold text-red-600">
                ₹{product.discountPrice}
              </span>
              <span className="text-gray-500 line-through text-2xl">
                ₹{product.price}
              </span>
              <span className="text-[#5C2028] font-bold">
                ({product.discountPercentage}% OFF)
              </span>
            </div>

            <div
              className="flex flex-wrap items-center space-x-2 md:space-x-4"
              style={{ marginBottom: "20px" }}
            >
              <p className="pr-2 text-lg md:text-2xl">Size -</p>
              <button
                className={`px-4 py-2 rounded-lg border text-sm md:text-base bg-[#E9C876]`}
              >
                {product.size.charAt(0).toUpperCase() +
                  product.size.slice(1).toLowerCase()}
              </button>
            </div>

            <div
              className="flex items-center space-x-2 mt-4"
              style={{ marginBottom: "20px" }}
            >
              <button
                className="flex-1 py-2 sm:py-3.5 bg-[#E9C876] text-white font-bold rounded-full hover:bg-[#d1a963] transition duration-200 max-w-xs md:max-w-60"
                onClick={() => handleAddToCart(product._id)}
              >
                Add to Cart
              </button>
              <div className="flex items-center space-x-2 bg-[#F4F0EF] rounded-full lg:p-2 p-0.5">
                <button
                  onClick={() => handleQuantityChange(-1)}
                  className="px-3 py-1 text-lg md:text-xl text-white rounded-full"
                >
                  -
                </button>
                <span className="text-lg md:text-xl">{quantity}</span>
                <button
                  onClick={() => handleQuantityChange(1)}
                  className="px-3 py-1 text-lg md:text-xl text-white rounded-full"
                >
                  +
                </button>
              </div>
            </div>

            <div className="flex flex-col space-y-2 mt-4">
              <button
                className="w-full lg:w-auto py-2 sm:py-3 bg-[#5C2028] font-medium rounded-full hover:bg-[#471919] transition duration-200 max-w-xs sm:max-w-sm text-[#ffff] text-xl mx-auto lg:mx-0"
                style={{ marginBottom: "10px" }}
                onClick={handleBuyItNow}
              >
                Buy It Now
              </button>
              <button
                className="w-full py-2 sm:py-3 bg-gray-100 text-gray-700 font-bold rounded-full hover:bg-gray-200 transition duration-200 flex items-center justify-center space-x-2 mx-auto lg:justify-start"
                onClick={() => handleWishlistToggle(product._id)}
              >
                {isInWishlist ? (
                  <FaHeart
                    className="text-red-600"
                    size={36}
                    style={{ color: "red" }}
                  />
                ) : (
                  <FaRegHeart className="text-red-600" size={36} />
                )}
                <span className="text-center lg:text-left font-medium text-xl">
                  {isInWishlist ? "Remove from Wishlist" : "Add to Wishlist"}
                </span>
              </button>
            </div>
          </div>
        </div>

        <div className="py-8 ">
          <h3 className="text-2xl font-bold mb-4">Product Specifications</h3>
          <Table data={product.specifications} />
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
