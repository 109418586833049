import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import { BASE_URL } from "../../constants/url";

const ChartComponent = ({ title }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const link = {
    Users: "get-user-chart",
    Sales: "get-sales-chart",
    Orders: "get-order-chart",
    Products: "get-order-chart",
  };

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BASE_URL}/dashboard/${link[title]}`
        );

        // Log the fetched data

        // Validate and process the data
        // const data = Array.isArray(response.data) ? response.data : [];
        //setChartData(response.data.data);

        console.log(response.data.data)
        if(title=="Users" || true){
          let newData = response.data?.data?.map(it => ({month : it.month,  totalSales : it.total}))
          setChartData([...newData,])
        }
        setLoading(false);
      } catch (error) {
        setError("Error fetching chart data");
        setLoading(false);
      }
    };

    fetchChartData();
  }, []);

  // Handle and format data
  const categories =
    chartData.length > 0
      ? chartData.map((data) => data.month || "Unknown")
      : ["No Data"];

  const seriesData =
    chartData.length > 0 ? chartData.map((data) => data.totalSales || 0) : [0];

  const options = {
    chart: {
      type: "line",
    },
    xaxis: {
      categories: categories,
    },
    title: {
      text: title || "Chart Data",
    },
  };

  const series = [
    {
      name: "Total",
      data: seriesData,
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div
      className="p-4 bg-white rounded shadow"
      style={{ marginLeft: "250px" }}
    >
      <h2 className="text-xl mb-4">{title}</h2>
      <Chart options={options} series={series} type="line" />
    </div>
  );
};

export default ChartComponent;
